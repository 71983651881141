<template>
  <button class="btn btn-primary d-flex justify-content-between align-items-center" style="padding-right:18px;" @click="$emit('input')">
    {{ name }}
    <button class="close btn p-0 m-0 gap-0 ms-1" @click="$emit('subinput')">
      <component
        :is="icon"
        :width="width"
        :height="height"
        class="fill-white"
      ></component>
    </button>
  </button>
</template>

<script>
import {
  // ListOutline,
  QuestionOutline,
  BIconFilterOutline,
  CloseOutline,
  DisplayConfig,
  DeleteFill,
  EditFill,
  AddFill,
  SortArrow,
  Drag,
  RemoveFill,
  moveDown,
  ExpandIn,
  ExpandOut,
  AddOutline,
  OpenEye,
  NotiOutline,
  moveUp,
  EdituserField,
  EdituserList,
  SearchOutline,
} from "@/components/icon/index";
export default {
  data() {
    return {};
  },
  props: {
    name: { type: String },
    icon: { type: String },
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
    classBtnIcon: { type: String, default: "" },
  },
  
  components: {
    // Icon name
    QuestionOutline,
    BIconFilterOutline,
    CloseOutline,
    DisplayConfig,
    DeleteFill,
    EditFill,
    AddFill,
    SortArrow,
    Drag,
    RemoveFill,
    moveDown,
    ExpandIn,
    ExpandOut,
    AddOutline,
    OpenEye,
    NotiOutline,
    moveUp,
    EdituserField,
    EdituserList,
    SearchOutline,
  },
  methods:{
    onSubClick(){
        console.log("Yes")
    }
  },
  emits: ["input","subinput"],
};
</script>
<style lang="scss" scoped>
    .close{
        position: absolute;
        right: 0;
        top: -3px;
    }
</style>


