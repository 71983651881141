<template>
  <!-- <div>UserRoleManager</div> -->
  <Header
    addHeader="d-none"
    icon="campusOutline"
    :headerName="$t('UserRoleManager')"
    :breadcrumbname="
      action == 'Insert'
        ? $t('Add UserRoleManager')
        : '' || action == 'Update'
        ? $t('Edit UserRoleManager')
        : ''
    "
    :btnName="$t('Add UserRoleManager')"
    class="mb-3"
    @onInput="openform($event)"
    :breadcrumb="
      blnOpenform == true || blnUserRoleGroupForm == true || blnShowUserLink
        ? ''
        : 'd-none'
    "
    :helperHeader="
      blnOpenform == true
        ? $t('525')
        : blnUserRoleGroupForm == true
        ? $t('526')
        : blnShowUserLink == true
        ? 'Please provide user group member'
        : ''
    "
    :hintHelperHeader="
      blnOpenform == true ||
      blnUserRoleGroupForm == true ||
      blnShowUserLink == true
        ? ''
        : 'd-none'
    "
    :rightHeader="
      blnOpenform == true ||
      blnUserRoleGroupForm == true ||
      blnShowUserLink == true
        ? 'd-none'
        : ''
    "
    :underline="
      blnOpenform == true ||
      blnUserRoleGroupForm == true ||
      blnShowUserLink == true
        ? 'openUnderline'
        : 'closeUnderline'
    "
    @showFilter="openFilter($event)"
    id="header"
    ListCampusClass="d-none"
    :Searchname="'Search UserRoleGroup'"
    @onSearch="SearchUserGroup($event)"
    @onClean="clean()"
    :blnOpenform="
      blnOpenform == true || blnUserRoleGroupForm == true ? true : false
    "
    :showFilter="blnOpenFilter"
    :filterActive="{
      blnOpenform: blnOpenFilter,
      useFilter: activeFiltersSelected.length == 0 ? false : true,
      disableFilter: true,
    }"
    :tooltipPlacement="
      device == 'mobile' ? 'tooltip-top-mobile' : 'tooltip-top'
    "
    blnShowSearchInput="false"
    ref="header"
  />
  <div
    v-if="blnEditRole == false"
    ref="AddMember"
    class="p-5 rounded-3 shadow-sm bg-white"
    :style="
      device == 'mobile' || device == 'tablet' ? 'width:80% !important;' : ''
    "
  >
    <div class="mb-4">
      <div class="d-flex mb-3 gap-3">
        <template v-for="item in listradioUsertype" :key="item.id">
          <radio
            class=""
            inputname="radioUsertype"
            :name="$t(item.name)"
            :usechecked="item.id == 0 ? true : false"
            @change="radioUserType(item)"
          />
        </template>
      </div>
      <div class="d-flex justify-content-center">
        <div class="w-100">
          <Search
            :hint="$t('314')"
            v-if="blnEditRole == false"
            ref="inputname"
            @onSearch="
              (e) => {
                strInputname = e;
              }
            "
            classAutoSearch="h-100"
          />
        </div>
        <div class="ms-3">
          <Button
            icon="SearchOutline"
            :name="$t('447')"
            classBtnIcon="stroke-white me-2"
            classBtn="btn-fix btn-primary d-flex"
            @input="processSearchUser(strInputname)"
          />
        </div>
      </div>
    </div>
    <Loading1 v-if="progressSearch == 0" />
    <div v-if="progressSearch == 100" class="scroll-load">
      <table class="table" aria-describedby="tableUserRoleManage">
        <thead>
          <tr>
            <th scope="col" class="fnt-body" style="width: 0">
              <div
                class="d-flex align-items-center justify-content-center bg-white"
              >
                <label
                  class="fnt-body fnt-bold text-black-500 ms-1"
                  style="margin-bottom: 1.55px !important"
                >
                  {{ $t("18") }}
                </label>
              </div>
            </th>
            <template
              v-for="(itemsState, indexState) in selectedUserList"
              :key="indexState"
            >
              <th scope="col" class="fnt-body">
                <div
                  class="d-flex align-items-center justify-content-start bg-white"
                >
                  <label
                    class="fnt-body fnt-bold text-black-500 ms-1"
                    style="margin-bottom: 1.55px !important"
                  >
                    {{ $t(itemsState.head) }}
                  </label>
                </div>
              </th>
            </template>

            <th scope="col" class="fnt-body" style="width: 0">
              <div
                class="d-flex align-items-center justify-content-start bg-white"
              >
                <label
                  class="fnt-body fnt-bold text-black-500 ms-1"
                  style="margin-bottom: 1.55px !important"
                >
                </label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody
          class="border-0"
          v-if="listSortUserData != null || listSortUserData != ''"
        >
          <tr
            v-for="(items, index) in listSortUserData"
            :key="index"
            class="border-bottom"
          >
            <th>
              <div
                class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
              >
                {{ parseInt(index) + 1 }}
              </div>
            </th>

            <td
              v-for="(itemsState, indexState) in selectedUserList"
              :key="indexState"
              class="fnt-regular fnt-body"
            >
              <div class="d-flex align-items-center mt-1">
                {{ items[itemsState.Field] }}
              </div>
            </td>
            <!-- {{
              items.intMemberID
            }} -->
            <td class="fnt-regular fnt-body">
              <div class="d-flex align-items-center">
                <Button
                  icon="EditFill"
                  classBtnIcon="fill-secondary"
                  widthIcon="20"
                  heightIcon="20"
                  classBtn="p-0 m-0"
                  class="pe-3"
                  @click="EditUserRole(items.intMemberID)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <NotFound
        v-if="listSortUserData == null || listSortUserData == ''"
        :style="
          listSortUserData == null || listSortUserData == ''
            ? 'height:fit-content;'
            : ''
        "
        class="border-bottom w-100 notfound"
        :name="$t('649')"
      />
    </div>
  </div>
  <Popuptable
    v-if="blnOpenPopupUserRole == true"
    :listDataField="ListUserRoleGroup.dataFields"
    :listDataTable="ListUserRoleGroup.data"
    @cancel="
      () => {
        blnOpenPopupUserRole = false;
      }
    "
    @confirm="GetUserRoleGroupByID($event)"
  />
  <div
    v-if="blnEditRole == true"
    ref="AddMember"
    class="p-5 rounded-3 shadow-sm bg-white m-sm-4"
    :style="
      device == 'mobile' || device == 'tablet' ? 'width:80% !important;' : ''
    "
  >
    <div class="d-flex justify-content-between">
      <!-- <p>UserRole Manager</p> -->
      <div class="d-flex">
        <div>
          <Avatar :image="imageProfile" />
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex">
            <p>
              {{ username }}
            </p>
            <!-- <p>{{ listMenu.userLink.strLastNameEN }}</p> -->
          </div>
          <div class="d-flex">
            <p>
              {{ usertypename }}
            </p>

            <p>{{ listMenu.userLink.strCode }}</p>
          </div>
        </div>
      </div>
      <div>
        <Button
          name="AddRole"
          classBtnIcon="stroke-white me-2"
          classBtn="btn-fix btn-primary d-flex"
          @click="OpenPopupUserRole()"
        />
      </div>
    </div>
    <div
      class="p-0 rounded-3 shadow-sm config-display bg-white m-sm-4"
      :style="
        device == 'mobile' || device == 'tablet' ? 'width:80% !important;' : ''
      "
    >
      <div class="d-flex" v-if="Loading == false">
        <label
          for=""
          class="fnt-medium fnt-subtitle mb-3"
          :class="device == 'mobile' ? 'fnt-body' : ''"
          >{{ $t("517") + " : " }}</label
        >
        <template v-for="(item, index) in listMenu.userRoleGroups" :key="index">
          <!-- {{ item.strUserRoleGroupID }} -->
          <!-- <Button
            v-if="
              item.strUserRoleGroupID == 0 && listMenu.userRoleGroups.length > 2
            "
            :name="item.strRoleGroupNameEN"
            classBtnIcon="stroke-white me-2"
            classBtn="btn-fix btn-primary d-flex"
            @click="ChangeTabID(index)"
          /> -->
          <ButtonTag
            v-if="item.strUserRoleGroupID !== 0"
            :name="item.strRoleGroupNameEN"
            classBtn="btn-fix btn-primary d-flex"
            icon="CloseOutline"
            classBtnIcon="fill-white"
            @subinput="RemoveRole(item.strUserRoleGroupID)"
          />
        </template>
      </div>

      <div class="row g-3">
        <Search
          :mode="'auto'"
          :hint="$t('447', { fieldname: $t('528').toLowerCase() })"
          @onSearch="searchUserFieldList($event)"
          @onClean="clear()"
          ref="Search"
        />
      </div>

      <!-- {{ intTabUserRole }}
      {{ ListSortData.moduleAccesses }} -->

      <div
        v-if="Loading == false"
        class="tree-container"
        :style="'height:fit-content;'"
        :class="'none-scrollbar'"
      >
        <table class="table m-0" aria-describedby="tableAddmember">
          <thead>
            <tr class style="text-align: left">
              <th scope="col" class="fnt-body" style="width: 0">
                <div class="d-flex align-items-center justify-content-center">
                  <label
                    class="fnt-body fnt-bold text-black-500 ms-1"
                    style="margin-bottom: 1.55px !important"
                  >
                    {{ $t("18") }}
                  </label>
                </div>
              </th>
              <th scope="col" class="fnt-body">
                <div class="d-flex align-items-center">
                  <button
                    class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                  >
                    {{ "intModuleUsed" }}
                  </button>
                </div>
              </th>
              <th scope="col" class="fnt-body">
                <div class="d-flex align-items-center">
                  <button
                    class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                  >
                    {{ "NameEN" }}
                  </button>
                </div>
              </th>
              <th scope="col" class="fnt-body">
                <div class="d-flex align-items-center">
                  <button
                    class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                  >
                    {{ "NameTH" }}
                  </button>
                </div>
              </th>
            </tr>
          </thead>

          <tbody
            class="border-0"
            v-if="ListSortData.moduleAccesses.length != 0"
          >
            <template
              v-for="(item, index) in ListSortData.moduleAccesses"
              :key="index"
            >
              <tr
                :class="item.highlight == true ? 'bg-secondary-50' : ''"
                class="border-bottom"
                @click="expand(item.strModuleID)"
              >
                <!-- {{ item.strModuleID }} -->
                <th id="">
                  <div
                    class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
                  >
                    <!-- {{ item.level }} -->
                    <!-- {{ index }}  -->

                    {{ parseInt(index) + 1 }}
                  </div>
                </th>
                <td class="fnt-regular fnt-body first-row">
                  <div class="mt-1">
                    <span
                      class="q-tree-link q-tree-label d-flex align-items-center"
                    >
                      <moveDown
                        width="18"
                        class="fill-secondary me-2"
                        :class="
                          item.children.length > 0
                            ? listopened.includes(item.strModuleID)
                              ? 'rotate-180'
                              : 'rotate-180-revert'
                            : 'opacity-0'
                        "
                      />

                      <input
                        type="checkbox"
                        id="theCheckbox"
                        :checked="item['intModuleUsed'] !== 0 ? true : false"
                        :indeterminate="
                          CalModuleUsed(index) == false ? true : false
                        "
                        @change="AccessModule($event, item['strModuleID'])"
                      />
                    </span>
                  </div>
                </td>
                <td class="fnt-regular fnt-body first-row">
                  <div class="mt-1">
                    <span lass="q-tree-link q-tree-label">
                      {{ item["strModuleNameEN"] }}
                    </span>
                  </div>
                </td>
                <td class="fnt-regular fnt-body first-row">
                  <div class="mt-1">
                    <span lass="q-tree-link q-tree-label">
                      {{ item["strModuleNameTH"] }}
                    </span>
                  </div>
                </td>
              </tr>
              <transition name="move" class="">
                <tr
                  v-if="listopened.includes(item.strModuleID)"
                  class="border-bottom"
                >
                  <td
                    colspan="12"
                    class="table-expand"
                    v-if="item.children.length > 0"
                  >
                    <div>
                      <table class="table table-hover" aria-describedby="tableExpand">
                        <tbody>
                          <tr
                            v-for="(child, index) in item.children"
                            :key="index"
                          >
                            <td>
                              <div
                                class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
                              >
                                {{ parseInt(index) + 1 }}
                              </div>
                            </td>
                            <td class="fnt-regular fnt-body first-row">
                              <div class="mt-1">
                                <span lass="q-tree-link q-tree-label">
                                  <input
                                    type="checkbox"
                                    id="theCheckbox"
                                    :checked="child['intMenuUsed']"
                                    @change="
                                      AccessMenu(
                                        $event,
                                        child['strMenuID'],
                                        child['strModuleID']
                                      )
                                    "
                                  />
                                </span>
                              </div>
                            </td>
                            <td class="fnt-regular fnt-body first-row">
                              <div class="mt-1">
                                <span lass="q-tree-link q-tree-label">
                                  {{ child["strMenuNameEN"] }}
                                </span>
                              </div>
                            </td>
                            <td class="fnt-regular fnt-body first-row">
                              <div class="mt-1">
                                <span lass="q-tree-link q-tree-label">
                                  {{ child["strMenuNameTH"] }}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                  <!-- <th>
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      fnt-regular fnt-body
                      mt-1
                    "
                  >
                    {{ parseInt(index) + 1 }}
                  </div>
                </th> -->
                </tr>
              </transition>
            </template>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-end mt-lg-3 mt-sm-2 w-100">
        <Button
          aria-label="Close"
          :name="$t('36')"
          @click="FetchInsertUserRoleManager()"
          class="btn-fix btn-primary me-3"
          :class="classOkBtn"
        />

        <Button
          aria-label="Close"
          :name="$t('35')"
          @click="cancel()"
          class="btn-fix btn-cancel"
          :class="classCancelBtn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/headerMenu/haeder.vue";
import radio from "@/components/input/radio.vue";
import Button from "@/components/button/button.vue";
import NotFound from "@/components/notFound/notFound.vue";
import Search from "@/components/input/inputSearch.vue";
import customfilter from "@/typescript/filter";
import axios from "axios";
import apiuserrolemanage from "@/typescript/urlapi/adminsystem/APIUserRoleManager";
import * as AesEncrytion from "@/typescript/AesEncryption";
import urlapi from "@/typescript/urlapi/url";
import { moveDown } from "@/components/icon/index";
import apiuserrolegroup from "@/typescript/urlapi/adminsystem/APIUserroleGroup";
import Avatar from "@/components/avatar/avatar.vue";
import Popuptable from "@/components/PopupTable.vue";
import ButtonTag from "@/components/button/buttonTag.vue";
import Loading1 from "@/components/Skeleton.vue";

export default {
  components: {
    Header,
    radio,
    Button,
    NotFound,
    Search,
    moveDown,
    Loading1,
    Popuptable,
    ButtonTag,
    Avatar,
  },

  data() {
    return {
      intMemberID: null,
      strMode: "UserRoleGroup",
      lstUserRoleGroupList: { type: Object },
      blnEditRole: false,
      
      blnPopupConfirmDelete: false,
      strSearch: "",
      msgalertDataDuplicate: [], //msg
      action: "Insert", //action of form
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      blnAltLoadingData: false, //Alt Status logint
      blnnotedit: false,
      blnnotdelete: false,
      hover: false, //hover alt
      timerEnabled: false,
      timerCount: 0,
      timerCountHover: 0,
      progress: 0,
      formdata: {
        strRoleGroupNameTH: "",
        strRoleGroupNameEN: "",
        intRoleGroupStatus: 0,
        roleModule: [],
      },
      formdataDummy: {},
      campusId: null,
      blnOpenform: false,
      ListCampus: {},
      ListDummy: {},
      formInactive: [],
      blnOpenFilter: false,
      blnopenConfigDisplay: false,
      blnError: false,
      intPinCount: 1,
      strTel: "",
      activeFiltersSelected: [],
      listRelate: [],
      updateAdsInventories: null,

      intWrongLogin: 0,
      blnUserRoleGroupForm: false,
      blnShowUserLink: false,
      blnOpenAddmember: false,
      lstUserLink: { type: Array },
      lstUserFieldList: { type: Object },
      lstUserFieldLink: { type: Object },
      lstuserGroupInfo: { type: Array },
      progresstableGroupAccess: 0,
      strInputname: "",
      listselecFromcheckbox: [],
      DummylistselecFromcheckbox: [],
      blnCheckall: false,
      blnOpenDataMySelected: false,
      intSearchResult: 0,
      strRadioUserType: "Student",
      intRadioUserType: 1,
      listradioUsertype: [
        {
          id: 0,
          name: "450", //นักศึกษา
        },
        {
          id: 1,
          name: "451", //เจ้าหน้าที่
        },
        {
          id: 2,
          name: "452", //บุคคลภายนอก
        },
      ],
      listSortUserData: [],
      SelectedUserList: [],
      blnopenmember: false,
      intmaxcount: 20,
      intmincount: 0,
      listMenu: [],
      listopened: [],
      Loading: true,
      intTabUserRole: 0,
      ListDummyUserRoleGroup: [],
      ListUserRoleGroup: [],
      blnOpenPopupUserRole: false,
      progressSearch: 100,
    };
  },
  created() {
    // this.fetchListUserRoleGroup();
  },

  mounted() {
    this.SelectedUserList = JSON.parse(localStorage.getItem("UserOfUserRole"));
  },
  computed: {
    username() {
      return this.$i18n.locale == "EN" || this.$i18n.locale == "en"
        ? this.listMenu.userLink.strFirstNameEN +
            " " +
            this.listMenu.userLink.strLastNameEN
        : this.listMenu.userLink.strFirstNameTH +
            " " +
            this.listMenu.userLink.strLastNameTH;

      // {{ listMenu.userLink.strFirstNameTH }}
      //     {{ listMenu.userLink.strLastNameTH }}
    },
    usertypename() {
      return this.$i18n.locale == "EN" || this.$i18n.locale == "en"
        ? this.listMenu.userLink.strUsertypeNameEN
        : this.listMenu.userLink.strUsertypeNameTH;
    },
    imageProfile() {
      return "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80";
    },
    ListSortData() {
      return this.listMenu.userRoleGroups[this.intTabUserRole];
    },
    listSortUserDatas() {
      return Object.values(this.listSortUserData).slice(
        this.mincount,
        this.intmaxcount
      );
    },
    selectedUserList() {
      let select = [];
      console.log(this.$i18n.locale);
      if (this.SelectedUserList != null) {
        if (this.$i18n.locale == "en" || this.$i18n.locale == "EN") {
          select = this.SelectedUserList.filter((ele) => {
            if (ele.Field != "strCode") {
              return ele.Field.includes("NameEN");
            } else {
              return ele.Field;
            }
          });
        }
        if (this.$i18n.locale == "th" || this.$i18n.locale == "TH") {
          select = this.SelectedUserList.filter((ele) => {
            if (ele.Field != "strCode") {
              return ele.Field.includes("NameTH");
            } else {
              return ele.Field;
            }
          });
        }
      }
      return select;
    },
    // test() {
    //   return this.listSortUserData.filter(e => e.intMemberID);
    // },
  },
  methods: {
    SearchParameterList(event) {
      this.strSearch = event;
      this.$refs.table.SearchListDataTable(event);
    },
    async FetchInsertUserRoleManager() {
      var UserRoleManager = {
        intMemberID: this.intMemberID,
        listUserRoleGroupID: [],
        listModuleAccess: this.listMenu.userRoleGroups[0].moduleAccesses,
      };

      this.listMenu.userRoleGroups.forEach((element) => {
        console.log(element.strUserRoleGroupID);
        if (element.strUserRoleGroupID !== 0) {
          UserRoleManager.listUserRoleGroupID.push(element.strUserRoleGroupID);
        }
      });

      console.log(UserRoleManager);
      await axios
        .post(urlapi + apiuserrolemanage.InsertUserRoleManager, UserRoleManager)
        .then((res) => {
          this.Loading = true;
          if (res.data.inserted == true) {
            this.Loading = false;
            this.blnOpenPopupUserRole = false;
            this.blnEditRole = false;

            this.listSortUserData = [];
            this.intSearchResult = null;

            console.log(this.listSortUserData);
            // this.listSortUserData = decrypt.data;
            this.SelectedUserList = [];
          }
          console.log(res.data);
        });
    },
    async GetUserRoleGroupByID(UserRoleGroupID) {
      console.log(UserRoleGroupID);
      this.Loading = true;
      await axios
        .get(urlapi + apiuserrolemanage.GetUserroleGroupbyID + UserRoleGroupID)
        .then((res) => {
          console.log(res.data);
          this.listMenu.userRoleGroups.push(res.data.data);
          console.log(this.listMenu.userRoleGroups);
          setTimeout(() => {
            var moduleAccesses = [];
            this.listMenu.userRoleGroups.forEach((element) => {
              console.log(element.strUserRoleGroupID);
              // if (element.strUserRoleGroupID !== 0) {
              moduleAccesses = Object.assign(
                moduleAccesses,
                moduleAccesses,
                element.moduleAccesses
              );
              // }
            });
            console.log(moduleAccesses);
            this.listMenu.userRoleGroups[0].moduleAccesses = moduleAccesses;
          }, 1000);

          setTimeout(() => {
            this.intTabUserRole =
              this.listMenu.userRoleGroups.length > 2 ? 0 : 1;
            this.Loading = false;
          }, 200);

          this.blnOpenPopupUserRole = false;
        })
        .catch((e) => {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
          console.log(e);
        });
    },

    RemoveRole(index) {
      this.Loading = true;
      //item.strUserRoleGroupID
      this.listMenu.userRoleGroups = this.listMenu.userRoleGroups.filter(
        (el) => el.strUserRoleGroupID !== index
      );
      setTimeout(() => {
        var moduleAccesses = [];
        this.listMenu.userRoleGroups.forEach((element) => {
          console.log(element.strUserRoleGroupID);
          // if (element.strUserRoleGroupID !== 0) {
          moduleAccesses = Object.assign(
            moduleAccesses,
            moduleAccesses,
            element.moduleAccesses
          );
          // }
        });
        console.log(moduleAccesses);
        this.listMenu.userRoleGroups[0].moduleAccesses = moduleAccesses;
      }, 1000);

      console.log(index);
      console.log(this.listMenu);

      setTimeout(() => {
        this.intTabUserRole = this.listMenu.userRoleGroups.length > 2 ? 0 : 1;
        this.Loading = false;
      }, 200);
    },
    OpenPopupUserRole() {
      this.fetchListUserRoleGroup();
      this.blnOpenPopupUserRole = true;
    },
    async fetchListUserRoleGroup() {
      this.timerCount = 0;
      this.timerEnabled = true;
      console.log(urlapi + apiuserrolegroup.GetUserroleGroupList);
      await axios
        .get(urlapi + apiuserrolegroup.GetUserroleGroupList)
        .then((res) => {
          console.log(res.data);
          this.ListDummyUserRoleGroup = res.data;
          this.ListUserRoleGroup = res.data;
          //เช็คข้อมูลวิทยาเขต ว่าลบได้หรือไม่
        })
        .catch((e) => {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
          console.log(e);
        });
      return true;
    },

    EditUserRole(MemberID) {
      this.fetchGetModuleList(MemberID);
    },
    async processSearchUser(keyword) {
      this.progressSearch = 0;
      try {
        if (
          keyword != null &&
          keyword != undefined &&
          keyword !== "" &&
          keyword.length > 1
        ) {
          console.log(
            urlapi +
              apiuserrolemanage.SearchUser +
              "?searchkeyword=" +
              keyword +
              "&UsertypeID=" +
              this.intRadioUserType
          );
          var res = await axios.get(
            urlapi +
              apiuserrolemanage.SearchUser +
              "?searchkeyword=" +
              keyword +
              "&UsertypeID=" +
              this.intRadioUserType
          );
          console.log(res.data.data);
          var decrypt = JSON.parse(AesEncrytion.decrypt(res.data.data));
          console.log(decrypt);
          console.log(decrypt.data);
          console.log(decrypt.dataFields);
          // let test = this.filters(decrypt.data,'นักศึกษา')
          // console.log(test);
          // if (this.listselecFromcheckbox.length == 0) {
          this.listSortUserData = decrypt.data;
          this.intSearchResult = this.listSortUserData.length;

          this.listSortUserData = this.listSortUserData.filter((item) =>
            item.strUserGrouptypeNameEN.includes(this.strRadioUserType)
          );
          this.intSearchResult = this.listSortUserData.length;
          console.log(this.listSortUserData);
          // this.listSortUserData = decrypt.data;
          this.SelectedUserList = await decrypt.dataFields;
          setTimeout(() => {
            console.log("progressSearch:100");
            this.progressSearch = 100;
          }, 500);

          // console.log(res.data.data);
          // this.blnopenmember = true;
        }
        if (keyword === "") {
          this.clean();
          this.blnopenmember = false;
        }
      } catch (error) {
        console.log(error);
        this.blnopenmember = false;
      }
    },
    ChangeTabID(ID) {
      this.Loading = true;
      this.intTabUserRole = ID;
      this.Loading = false;

      // var test = Object.assign(
      //   {},
      //   this.listMenu.userRoleGroups[1],
      //   this.listMenu.userRoleGroups[2]
      // );
    },

    async fetchGetModuleList(MemberID) {
      this.intMemberID = MemberID;
      try {
        console.log(
          urlapi + apiuserrolemanage.GetuUserRoleByMemberID + MemberID
        );
        var res = await axios.get(
          urlapi + apiuserrolemanage.GetuUserRoleByMemberID + MemberID
        );

        this.listMenu = res.data.data;
        this.lsitDummyMenu = { ...res.data.data };
        console.log(this.listMenu);
        this.intTabUserRole = 0;
        this.blnEditRole = true;
        this.Loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    // Expand Table ด้านใน
    expand(id) {
      this.blnexpand = !this.blnexpand;
      const index = this.listopened.indexOf(id);
      console.log(this.listopened.indexOf(id));
      if (index > -1) {
        this.listopened.splice(index, 1);
      } else {
        this.listopened.push(id);
      }
    },
    AccessMenu(event, menuID, moduleID) {
      console.log(event.target.checked);
      // console.log(event + " : " + menuID + " : " + moduleID);
      // this.$emit("addMenu", {
      //   event: event.target.checked,
      //   moduleID: moduleID,
      //   menuID: menuID,
      // });
      if (this.intTabUserRole == 0) {
        for (let i = 0; i < this.listMenu.userRoleGroups.length; i++) {
          this.listMenu.userRoleGroups[i].moduleAccesses.filter((ele) => {
            if (ele.strModuleID == moduleID) {
              ele.children.filter((child) => {
                if (child.strMenuID == menuID) {
                  child.intMenuUsed = event.target.checked;
                  // if (event.event == true) {
                  //   ele.intMenuUseCount += 1;
                  // } else if (event.event == false) {
                  //   ele.intMenuUseCount -= 1;
                  // }
                }
              });
            }
          });
        }
      }
    },
    CalModuleUsed(moduleID) {
      var blnUsed = false;
      var menucount =
        this.listMenu.userRoleGroups[0].moduleAccesses[moduleID].children
          .length;
      var menuused = 0;
      this.listMenu.userRoleGroups[0].moduleAccesses[moduleID].children.filter(
        (ele) => {
          if (ele.intMenuUsed == true) {
            menuused += 1;
          }
        }
      );

      if (menucount == menuused) {
        blnUsed = true;
      }

      return blnUsed;
    },
    AccessModule(event, moduleID) {
      console.log(event.target.checked);
      if (this.intTabUserRole == 0) {
        for (let i = 0; i < this.listMenu.userRoleGroups.length; i++) {
          this.listMenu.userRoleGroups[i].moduleAccesses.filter((ele) => {
            if (ele.strModuleID == moduleID) {
              ele.intModuleUsed = event.target.checked;
              ele.children.forEach((child) => {
                console.log(child);
                child.intMenuUsed = event.target.checked;
              });
              // if (event.event == true) {
              //   ele.intMenuUseCount = ele.listRoleMenus.length;
              // } else if (event.event == false) {
              //   ele.intMenuUseCount = 0;
              // }
            }
          });
          console.log(event);
        }
      }
    },
    filterLang(items, lang) {
      return customfilter.filterLang(items, lang);
      // console.log(this.defaultLang);
      // if (items !== undefined && this.$i18n.locale == null) {
      //   return customfilter.filterLang(items, this.$i18n.locale);
      // } else if (items !== undefined && this.$i18n.locale != null) {
      //   return customfilter.filterLang(items, this.$i18n.locale);
      // }
    },
    radioUserType(item) {
      console.log(item.id);
      // this.strRadioUserType = item.id;
      if (item.id == 0) {
        this.strRadioUserType = "Student";
        this.intRadioUserType = 1;
      } else if (item.id == 1) {
        this.strRadioUserType = "Officer";
        this.intRadioUserType = 2;
      } else if (item.id == 2) {
        this.strRadioUserType = "Others";
        this.intRadioUserType = 3;
      }
    },
  },
};
</script>

<style>
</style>