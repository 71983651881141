const apiuserrolemanage = {
    GetuUserRoleByMemberID: "/v1/api/AdsUserRoleManager/MemberRole/",
    InsertUserroleGroup: "/v1/api/AdsUserRole/InsertUserRoleGroup",
    GetUserroleGroupbyID: "/v1/api/AdsUserRoleManager/GetUserRoleGroupByID/",
    UpdateUserroleGroup: "/v1/api/AdsUserRole/UpdateUserroleGroup/",
    DeleteUserroleGroup: "/v1/api/AdsUserRole/DeleteUserRole/",
    DeleteUserOfUserroleGroup: "/v1/api/AdsUserRole/DeleteUserOfUserroleGroup/",
    GetModuleList: "/v1/api/AdsUserRole/Module/",
    SearchUser: "/v1/api/AdsUserRoleManager/SearchUserForRole",
    InsertUserRoleManager: "/v1/api/AdsUserRoleManager/InserUserRoleManage",
    GetMemberOfuserRoleGroup: "/v1/api/AdsUserRole/UserLink/"
};
export default apiuserrolemanage;

